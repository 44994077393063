import React from 'react'
import Page from '../components/Page'

const WhyContractUsPage = () => (
  <Page title="Why contract us?">
      <ul className="w-1/2 mt-8">
        <li className="p-4 mb-2 bg-main">OUR STRENGTH is YOUR SUCCESS</li>
        <li className="p-4 mb-2 bg-main">We have decades of experience in this area (since 2003) and can provide review, correction, certification, reports, and expert witness services, as necessary</li>
        <li className="p-4 mb-2 bg-main">Independent evaluation of translation</li>
        <li className="p-4 mb-2 bg-main">Independent translations</li>
        <li className="p-4 mb-2 bg-main">No sub-contractors involved</li>
        <li className="p-4 mb-2 bg-main">Guarantee of quality</li>
        <li className="p-4 mb-2 bg-main">Cultural adaption of arguments</li>
        <li className="p-4 mb-2 bg-main">Adjusting your arguments to other cultures</li>
      </ul>
  </Page>
)

export default WhyContractUsPage